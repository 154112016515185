import { useCurrency } from "@/composables/useCurrency";

import {
  date as filterDate,
  time as filterTime,
  numbro,
  capitalize,
} from "@/utils/filters";
import {
  pick,
  formatCurrencyCasing,
  getRoundedDownValue,
} from "@/utils/helpers";
import { add } from "@/utils/math";

import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  TRANSACTION_SIDE,
  TRADE_SIDE,
  API_KEY_STATUS,
} from "@/constants";

export const formatUserResponse = (user) => {
  const formattedUser = pick(user, [
    "id",
    "email",
    "role",
    "status",
    "forcePasswordChange",
    "twoFaEnabled",
    "accountUuid",
    "inRemidiation",
    "remidiationLink",
  ]);

  return {
    ...formattedUser,
  };
};

export const getTransactionStatusText = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.COMPLETED:
      return "Completed";
    case TRANSACTION_STATUS.PENDING:
      return "Pending";
    case TRANSACTION_STATUS.IN_PROGRESS:
      return "In progress";
    case TRANSACTION_STATUS.FAILED:
      return "Failed";
    case TRANSACTION_STATUS.AWAITING_APPROVAL:
      return "Awaiting approval";
    case TRANSACTION_STATUS.SETTLED:
      return "Settled";
    case TRANSACTION_STATUS.ROLLBACK:
      return "Rollback";
    default:
      return "Unknown";
  }
};

export const formatTradesForTable = (trades) => {
  const store = require("@/store/store");
  const state = store.default.state;
  const { uiFormatters } = state.config;
  const { convertTo } = useCurrency();

  return trades.map((trade) => {
    const date = filterDate(trade.timestamp);
    const time = filterTime(trade.timestamp);

    const sideText = trade.side === TRADE_SIDE.BUY ? "Buy" : "Sell";
    let { priceCurrency, quantityCurrency, totalCurrency } = trade;

    const formattedQuantity = numbro(trade.quantity, quantityCurrency, {
      rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
    });
    const convertedQuantity = getRoundedDownValue(
      trade.quantityConverted[convertTo.value]
    ).replace(/,/g, "");

    const formattedPrice = numbro(trade.price, priceCurrency, {
      rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
    });
    const convertedPrice = getRoundedDownValue(
      trade.priceConverted[convertTo.value]
    ).replace(/,/g, "");

    const formattedFee =
      trade.fee !== null && trade.fee !== undefined
        ? numbro(trade.fee, trade.feeCurrency?.toUpperCase(), {
            rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
          })
        : null;
    const convertedFee = trade.feeConverted
      ? getRoundedDownValue(trade.feeConverted[convertTo.value]).replace(
          /,/g,
          ""
        )
      : null;

    const formattedTotal = trade.total === null ? null : numbro(trade.total, totalCurrency, {
      rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
    });
    const convertedTotal = trade.total === null ? null : getRoundedDownValue(
      trade.totalConverted[convertTo.value]
    ).replace(/,/g, "");

    return {
      ...trade,
      date,
      time,
      sideText,
      formattedQuantity,
      quantityCurrency,
      convertedQuantity,
      formattedPrice,
      priceCurrency,
      convertedPrice,
      formattedFee,
      convertedFee,
      formattedTotal,
      totalCurrency,
      convertedTotal,
    };
  });
};

export const formatTransactionsForTable = (transactions) => {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const { uiFormatters } = state.config;
  const getApiKey = getters["portfolio/getApiKey"];
  const getCurrencyFullName = getters.getCurrencyFullName;
  const { convertTo } = useCurrency();

  return transactions.map((transaction) => {
    const formattedType = !transaction?.labels?.length
      ? transaction.type
      : transaction.labels[0];
    const formattedAmount = numbro(transaction.amount, transaction.currency, {
      rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
    });
    const convertedAmount = transaction.amountConverted[convertTo.value];
    const formattedFee = numbro(transaction.fee, transaction.currency, {
      rule: uiFormatters.ROUNDING_RULES.LONG_PRECISION,
    });
    const convertedFee = transaction.feeConverted[convertTo.value];
    const from = {};
    const to = {};

    const formatApiKeyName = (apiKeyId) => {
      const apiKey = getApiKey(apiKeyId);
      if (apiKeyId === null && formattedType.toLowerCase().includes("earn")) {
        return "Earn Account";
      }
      return apiKey ? apiKey.name : "Unknown Account";
    };

    const formatExchangeName = (exchangeId) => {
      if (exchangeId === null && formattedType.toLowerCase().includes("earn")) {
        return "Bequant";
      }

      return getters.getExchangeFullName(exchangeId) || "Unknown Exchange";
    };

    const formatTransferApiKeyName = (apiKeyId, apiKeyStatus) => {
      if (apiKeyStatus === API_KEY_STATUS.ACTIVE) {
        return formatApiKeyName(apiKeyId);
      } else if (apiKeyStatus === API_KEY_STATUS.BLOCKED) {
        return "Blocked Account";
      }

      return "Unknown Account";
    };

    if (transaction.type === TRANSACTION_TYPE.TRANSFER) {
      from.exchange = formatExchangeName(transaction.fromExchangeId);
      from.subaccount = formatTransferApiKeyName(
        transaction.fromApiKeyId,
        transaction.fromApiKeyStatus
      );
      to.exchange = formatExchangeName(transaction.toExchangeId);
      to.subaccount = formatTransferApiKeyName(
        transaction.toApiKeyId,
        transaction.toApiKeyStatus
      );
    } else {
      if (transaction.side === TRANSACTION_SIDE.POSITIVE) {
        from.exchange = "External Wallet";
        from.subaccount = transaction.address;
        to.exchange = formatExchangeName(transaction.exchangeId);
        to.subaccount = formatApiKeyName(transaction.apiKeyId);
      } else if (transaction.side === TRANSACTION_SIDE.NEGATIVE) {
        to.exchange = "External Wallet";
        to.subaccount = transaction.address;
        from.exchange = formatExchangeName(transaction.exchangeId);
        from.subaccount = formatApiKeyName(transaction.apiKeyId);
      } else {
        to.exchange = from.exchange = formatExchangeName(
          transaction.exchangeId
        );
        to.subaccount = from.subaccount = formatApiKeyName(
          transaction.apiKeyId
        );
      }
    }

    const fromSortKey = JSON.stringify(from);
    const toSortKey = JSON.stringify(to);

    return {
      ...transaction,
      formattedCurrency: formatCurrencyCasing(transaction.currency),
      date: filterDate(transaction.timestamp),
      time: filterTime(transaction.timestamp),
      statusText: getTransactionStatusText(transaction.status),
      formattedType,
      currencyFullName: getCurrencyFullName(transaction.currency),
      formattedAmount,
      convertedAmount,
      formattedFee,
      convertedFee,
      from,
      to,
      fromSortKey,
      toSortKey,
    };
  });
};

export const formatPortfolioAccounts = (accounts) => {
  return accounts
    .sort((a, b) => b.totalConverted - a.totalConverted);
};

export const formatSpotBalanceResponse = (spotBalance) => {
  const store = require("@/store/store");
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const currencyToAliasMap = getters.currencyToAliasMap;

  return spotBalance.map((item) => {
    const {
      balance,
      apiKeyId,
      balanceConverted,
      available,
      availableConverted,
      funding,
      fundingConverted,
    } = item;
    const { name: apiKeyName, exchangeId } = getApiKey(apiKeyId) ?? {};
    const exchangeFullName = getExchangeFullName(exchangeId);

    const reduceBalance = (memo, [key, value]) => {
      const aliasKey = `${exchangeId}:${key}`;
      const formattedKey = currencyToAliasMap[aliasKey] || key;

      memo[formattedKey] = add(value, memo[formattedKey] ?? 0);

      return memo;
    };

    const reduceConvertedBalance = (memo, [key, value]) => {
      const aliasKey = `${exchangeId}:${key}`;
      const formattedKey = currencyToAliasMap[aliasKey] || key;

      memo[formattedKey] = Object.entries(value).reduce(
        (acc, [valueKey, valueVal]) => {
          const currentValue = memo[formattedKey]
            ? memo[formattedKey][valueKey]
            : "0";

          acc[valueKey] = add(currentValue, valueVal);

          return acc;
        },
        {}
      );

      return memo;
    };
    
    return {
      ...item,
      apiKeyName,
      exchangeFullName,
      exchangeId,
      balance: Object.entries(balance || {}).reduce(reduceBalance, {}),
      balanceConverted: Object.entries(balanceConverted || {}).reduce(
        reduceConvertedBalance,
        {}
      ),
      available: Object.entries(available || {}).reduce(reduceBalance, {}),
      availableConverted: Object.entries(availableConverted || {}).reduce(
        reduceConvertedBalance,
        {}
      ),
      funding: Object.entries(funding || {}).reduce(reduceBalance, {}),
      fundingConverted: Object.entries(fundingConverted || {}).reduce(
        reduceConvertedBalance,
        {}
      ),      
    };
  });
};

export const formatTurnoverTradesResponse = (data) => {
  const store = require("@/store/store");
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const { convertToView } = useCurrency();

  return data.map((item) => {
    const { name } = getApiKey(item.apiKeyId) ?? {};
    const account = name ?? "-";

    const formattedVolumesValue = getRoundedDownValue(
      item.volumes[convertToView.value],
      null,
      false
    ).replace(/,/g, "");

    const feesValue = item.fees
      ? getRoundedDownValue(
          item.fees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;

    const feeValue = getRoundedDownValue(
      item.fee,
      item.feeCurrency,
      false
    ).replace(/,/g, "");
    const feePresentation = `${getRoundedDownValue(
      feeValue,
      item.feeCurrency,
      false
    )} ${item.feeCurrency}`;

    const averageFeesValue = item.averageFees[convertToView.value]
      ? getRoundedDownValue(
          item.averageFees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;
    const averageFeesPresentation = `${getRoundedDownValue(
      averageFeesValue,
      null,
      false
    )} BPS`;

    const pbFeesValue = item.pbFees
      ? getRoundedDownValue(
          item.pbFees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;
    const pbFeesPresentation = `${getRoundedDownValue(
      pbFeesValue,
      null,
      false
    )} ${convertToView.value}`;

    const pbFeePresentation = item.pbFeeCurrency
      ? `${getRoundedDownValue(item.pbFee, item.pbFeeCurrency, false)} ${
          item.pbFeeCurrency
        }`
      : "-";

    const pbAverageFeesValue = item.averagePbFees[convertToView.value]
      ? getRoundedDownValue(
          item.averagePbFees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;
    const pbAverageFeesPresentation = `${getRoundedDownValue(
      pbAverageFeesValue,
      null,
      false
    )} BPS`;

    const totalFeesValue = item.totalFees
      ? getRoundedDownValue(
          item.totalFees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;
    const totalFeesPresentation = `${getRoundedDownValue(
      totalFeesValue,
      null,
      false
    )} ${convertToView.value}`;

    const totalAverageFeesValue = item.totalAverageFees
      ? getRoundedDownValue(
          item.totalAverageFees[convertToView.value],
          null,
          false
        ).replace(/,/g, "")
      : 0;
    const totalAverageFeesPresentation = `${getRoundedDownValue(
      totalAverageFeesValue,
      null,
      false
    )} BPS`;

    const feeSchemeMap = {
      "all-in": "All-in",
      "mark-up": "Mark up",
      fixed: "Fixed",
      "profit-sharing": "Profit sharing",
    };

    return {
      contract: capitalize(item.contractType),
      account,
      exchange: item.exchangeId,
      exchangeFullName: getters.getExchangeFullName(item.exchangeId),
      maker: item.maker ? "Maker" : "Taker",
      symbol: item.symbol,
      tradesCount: item.tradesCount,
      formattedVolumesValue,
      scheme: feeSchemeMap[item.feeScheme] ?? "-",
      fees: item.fees,
      feesValue,
      feeValue,
      feePresentation,
      fee: item.fee,
      feeCurrency: item.feeCurrency,
      averageFees: item.averageFees,
      averageFeesValue,
      averageFeesPresentation,
      pbFees: item.pbFees,
      pbFeesValue,
      pbFeesPresentation,
      pbFee: item.pbFee,
      pbFeePresentation,
      averagePbFees: item.averagePbFees,
      pbAverageFeesValue,
      pbAverageFeesPresentation,
      totalFees: item.totalFees,
      totalFeesValue,
      totalFeesPresentation,
      totalAverageFees: item.totalAverageFees,
      totalAverageFeesValue,
      totalAverageFeesPresentation,
    };
  });
};
